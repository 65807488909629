*::selection {
  background: #000;
  color: #fff;
}
*::-moz-selection {
  background: #000;
  color: #fff;
}

*::-webkit-scrollbar {
  width: 0px;
  display: none;
}
*::-webkit-scrollbar-track {
  background-color: #fff;
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border: none;
}

html,
body {
  width: 100vw;
  height: var(--app-height);
  color: var(--text-color);
  transition: color 0.4s ease-in-out;
  overflow: hidden;
}

.splash {
  &__wrapper {
    width: 100%;
    height: var(--app-height);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__characters-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 10;
    justify-content: space-evenly;
  }

  &__item-wrapper {
    flex-grow: 1;
    border: 0.5px solid var(--text-color);
    transition: all 0.4s ease-in-out;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
    @include hover {
      color: #fff;
      .splash__poster-wrapper {
        opacity: 1;
      }
      .splash__logo-wrapper {
        border-color: #fff;
      }
    }
  }

  &__poster-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__logo-wrapper {
    width: calc(100% - 30px);
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid var(--text-color);
    transition: border-color 0.4s ease-in-out;
    &.mobile {
      display: none;
    }
  }

  &__cast-name-wrapper {
    text-align: center;
    position: absolute;
    padding: 15px;
    left: 0;
    width: calc(100% - 30px);
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
    }
    &.super-top {
      top: 100%;
    }
  }

  &__character-name-wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }

  &__footer-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 2;
  }

  &__footer-small-wrapper {
    width: calc(15% - 2px);
    border: 0.5px solid var(--text-color);
    transition: border-color 0.4s ease-in-out;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
  }

  &__ld-logo-wrapper {
    height: 100%;
    width: 30%;
    margin: 5px auto;
  }
  &__footer-large-wrapper {
    padding: 15px;
    width: calc(70% - 2px);
    text-align: center;
    border: 0.5px solid var(--text-color);
    transition: border-color 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__video-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: var(--app-height);
    transition: all 0.4s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    &.active {
      background-color: #000;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__video {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__vid-open-logo {
    margin: 15px;
    color: #fff;
    position: fixed;
    width: calc(100% - 30px);
    text-align: center;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
    z-index: 10;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__vid-close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.mobile {
  display: none;
}
@include mobile {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  html,
  body {
    width: 100vw;
    height: var(--app-height);
    color: var(--text-color);
    transition: color 0.4s ease-in-out;
    overflow-y: auto;
  }

  .splash {
    &__wrapper {
      width: 100%;
      height: auto;
      display: block;
    }

    &__characters-wrapper {
      display: block;
      margin-top: 91px;
    }

    &__item-wrapper {
      width: 100%;
      height: 30vh;
    }

    &__logo-wrapper {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
        position: fixed;
        top: 0;
        background-color: #fff;
        z-index: 3;
        transition: opacity 0.4s ease-in-out;
        &.disabled{
          opacity: 0;
          pointer-events: none;
        }
      }
      position: static;
      text-align: center;
    }

    &__cast-name-wrapper {
      &.super-top {
        top: auto;
        bottom: 0;
      }
    }

    &__vid-open-logo {
      
      top: 0;
    }

    &__video {
      min-height: none;
      min-width: none;
      width: 100%;
    }
  }
}
