@font-face {
  font-family: "Folio BQ";
  src: url("../assets/FolioBQ-Book.eot");
  src: url("../assets/FolioBQ-Book.eot?#iefix") format("embedded-opentype"),
    url("../assets/FolioBQ-Book.woff2") format("woff2"),
    url("../assets/FolioBQ-Book.woff") format("woff"),
    url("../assets/FolioBQ-Book.ttf") format("truetype"),
    url("../assets/FolioBQ-Book.svg#FolioBQ-Book") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: "Folio BQ", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
  @include hover {
    text-decoration: none;
  }
}

.splash__character-name-wrapper,
.splash__logo-wrapper,
.splash__vid-open-logo {
  font-size: 4vw;
  line-height: 3vw;
  letter-spacing: -0.22vw;
}
.splash__cast-name-wrapper {
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -1px;
}

.small-text {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.3px;
}

@include mobile {


html,
body {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -1px;
}
.splash__cast-name-wrapper {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -1px;
}
  .splash__character-name-wrapper,
  .splash__logo-wrapper,
  .splash__vid-open-logo {
    font-size: 40px;
    line-height: 30px;
    letter-spacing: -0.22px;
  }
}
