@keyframes letter-anim {
  0% {
    opacity: 0;
    transform: rotate3d(0, 90, 0, 90deg);
  }
  100% {
    opacity: 1;
    transform: rotate3d(0, 0, 0, 0deg);
  }
}

@keyframes text-anim {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.animation {
  &__outer-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: opacity 0.4s ease-in-out;
    &.disabled{
      opacity: 0;
      pointer-events: none;
    }
  }

  &__inner-wrapper{
    display: block;
    margin: 0 auto;
  }

  &__frame-wrapper{
    height: 200px;
    width: 200px;
    position: relative;
    perspective: 1000px;
  }

  &__text-wrapper{
    width: 0;
    height: auto;
    position: relative;
    animation: text-anim 0.8s ease-out 1 forwards;
    animation-delay: 1.2s;
    overflow: hidden;
    margin-top: 5px;
  }

  &__text{
    width: 200px;
    height: auto;
  }

  &__element{
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate3d(0, 80, 0, 80deg);
    opacity: 0;

    &.l{
      animation: letter-anim 0.8s ease-out 1 forwards;
      animation-delay: 1.2s;
    }
    &.d{
      animation: letter-anim 0.8s ease-out 1 forwards;
      animation-delay: 1s;
    }
    &.f{
      animation: letter-anim 0.8s ease-out 1 forwards;
      animation-delay: 1.4s;
    }

  }


}

